import React from "react";
import { Trans } from "@lingui/macro";
import { Box, Grid, Stack, Typography } from "@mui/material";
import _ from "lodash";
import {
  faLightbulbOn,
  faRankingStar,
  faToolbox,
  faScaleBalanced,
} from "@fortawesome/pro-light-svg-icons";
import styled from "@emotion/styled";
import NewLayout from "../components/NewLayout";
import Hero from "../components/Hero";
import Container from "../components/Container";
import TwoColumnsSection from "../components/TwoColumnsSection";
import InterestedItems from "../components/InterestedItems";
import { headers, restHeaders } from "../utils/navbar";
import peopleImage from "../images/careers/people.png";
import offerImage from "../images/careers/offer.png";
import CustomizedIcon from "../components/CustomizedIcon";
import ArrowButton from "../components/ArrowButton";
import backgroundImage from "../images/headers/careers.png";
import { colors } from "../theme";

const iconsContent = () => [
  {
    title: <Trans>All for Excellence</Trans>,
    description: (
      <Trans>
        All our experience and expertise, along with the passion we put into
        everything we do. That is why our clients, employees, and partners can
        count on us to have the best methods, the best services, the best
        technology and the best professionals at their fingertips.
      </Trans>
    ),
    icon: faRankingStar,
    color: colors.green.main,
  },
  {
    title: <Trans>All for Accountability</Trans>,
    description: (
      <Trans>
        We seek to achieve a balance between the interests of our clients, our
        employees and our shareholders, while continually looking to make a
        difference through our transparent and responsible attitude towards
        people and society.
      </Trans>
    ),
    icon: faScaleBalanced,
    color: colors.blue.main,
  },
  {
    title: <Trans>All for Empowerment</Trans>,
    description: (
      <Trans>
        We work to continuously enhance our tools and services and make them
        accessible to our clients. So they have the freedom to make decisions
        and choose what they want to do, whenever and however they need to.
      </Trans>
    ),
    icon: faToolbox,
    color: colors.orangeLight.main,
  },
  {
    title: <Trans>All for Inspiration</Trans>,
    description: (
      <Trans>
        People are our driving force and helping them reach their goals is our
        biggest motivator. That is why we aim to adapt to their needs and wants,
        to accompany them on their journey, and inspire them to achieve their
        dreams.
      </Trans>
    ),
    icon: faLightbulbOn,
    color: colors.red.main,
  },
];
const info = () => [
  {
    image: peopleImage,
    title: <Trans>Our People</Trans>,
    description: (
      <ul>
        <li>
          <Trans>
            Now represent more than 1000 employees in 17 offices around the
            world
          </Trans>
        </li>
        <li>
          <Trans>
            Are a diverse and open-minded collective that strive for excellence
            every day
          </Trans>
        </li>
        <li>
          <Trans>
            Share an international mindset, working with clients and
            stakeholders across 5 continents
          </Trans>
        </li>
        <li>
          <Trans>
            Are doers and problem solvers, proactively looking to advance their
            tasks and projects
          </Trans>
        </li>
        <li>
          <Trans>
            Are all-rounders, from many walks of life, that bring new insight
            and challenges to teams and projects
          </Trans>
        </li>
        <li>
          <Trans>
            Share the innovation mindset that founded Allfunds and drives it
            today
          </Trans>
        </li>
        <li>
          <Trans>
            Always work as a collective, put their best feet forward and remain
            goal oriented, the Allfunds teams share common objectives and work
            together to overcome challenges and achieve success
          </Trans>
        </li>
        <li>
          <Trans>
            Match Allfunds’ strong values of accountability and integrity"{" "}
          </Trans>
        </li>
      </ul>
    ),
  },
  {
    image: offerImage,
    title: <Trans>What we offer</Trans>,
    description: (
      <ul>
        <li>
          <Trans>
            Joining one of the leading financial technology companies worldwide
            at the forefront of product innovation that seeks to expand its
            reach and scope, accelerating the industry’s transformation.
          </Trans>
        </li>
        <li>
          <Trans>
            An environment that fosters career development opportunities across
            different geographies and lines of work
          </Trans>
        </li>
        <li>
          <Trans>Flexible and remote work alternatives</Trans>
        </li>
        <li>
          <Trans>Competitive remuneration and benefits packages</Trans>
        </li>
      </ul>
    ),
  },
];

function CareersPage() {
  const urlWork =
    "https://allfunds.csod.com/ux/ats/careersite/4/home?c=allfunds";
  const interestedItems = [
    { ..._.get(headers(), "investors.elements.investors_about") },
    { ..._.get(restHeaders(), "about.elements.about_team") },
    { ..._.get(restHeaders(), "about.elements.about_history") },
  ];

  return (
    <NewLayout apps="careers">
      <Hero
        kind={<Trans>Careers</Trans>}
        title={<Trans>Careers</Trans>}
        description={
          <Trans>Be a part of the wealth management transformation</Trans>
        }
        buttonText={<Trans>Work with us</Trans>}
        buttonPath={urlWork}
        backgroundImage={backgroundImage}
      />
      <Container py={8}>
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={4}>
            <Typography variant="h3">
              <Trans>Our purpose is to transform the WealthTech industry</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Stack spacing={2}>
              <Typography>
                <Trans>
                  We are building the largest and most sophisticated global B2B
                  fund distribution platform and believe that the right talent
                  is instrumental in this endeavor.
                </Trans>
              </Typography>
              <Typography>
                <Trans>
                  Allfunds has a solid 23 year history but is a fintech company
                  at heart: a fast-paced, dynamic environment coupled with solid
                  leadership and innovative mindset. We aim to grow and develop
                  our human capital with the best talent to match our exciting
                  challenge.
                </Trans>
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Box sx={{ mt: { xs: 5, sm: 10 } }}>
          {info().map(({ title, description, image }, index) => (
            <TwoColumnsSection
              color="#D50032"
              image={image}
              reverse={index % 2}
              title={title}
              description={description}
              mt={index > 0 && 2}
            />
          ))}
        </Box>
        <Box sx={{ mt: { xs: 5, sm: 10 } }}>
          <Typography variant="h3">
            <Trans>Our Values</Trans>
          </Typography>
          <Typography my={1}>
            <Trans>
              We have a clear set of values that we expect all our employees to
              work and live by
            </Trans>
          </Typography>
          <Grid container spacing={2} mt={{ xs: 1, sm: 3 }}>
            {iconsContent().map(({ title, description, icon, color }) => (
              <Grid item xs={12} sm={6} md={3}>
                <CustomizedIcon
                  icon={icon}
                  color={color}
                  title={title}
                  description={description}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Box p={4} sx={{ background: (theme) => theme.palette.primary.main }}>
        <Container textAlign="center">
          <WhiteTypography variant="h3">
            <Trans>Work with us</Trans>
          </WhiteTypography>
          <Box my={2}>
            <WhiteTypography variant="text">
              <Trans>
                Check out our latest list of job vacancies and apply.
              </Trans>
            </WhiteTypography>
          </Box>
          <ArrowButton
            title={<Trans>Learn more</Trans>}
            onClick={() => window.open(urlWork)}
          />
        </Container>
      </Box>
      <Container pb={8}>
        <InterestedItems items={interestedItems} />
      </Container>
    </NewLayout>
  );
}

const WhiteTypography = styled(Typography)`
  color: white;
`;

export default CareersPage;
