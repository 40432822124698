import React from "react";
import { Stack, Grid, Typography, Box, useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";

function TwoColumnsSection({
  title,
  description,
  children,
  image,
  color = "#009989",
  reverse = false,
  ...rest
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const renderImage = () => (
    <Grid item xs={12} md={6}>
      <Box textAlign="center">
        <Box
          sx={{
            margin: "0 auto 36px",
            width: "90%",
            height: isMobile ? "350px" : "500px",
            display: "block",
            background: `linear-gradient(0deg, rgba(27, 54, 93, 0.15), rgba(27, 54, 93, 0.15)), url(${image})`,
            position: "relative",
            backgroundPosition: "center",
            borderRadius: "5px",
            backgroundSize: "cover !important",
          }}
        />
        <Box
          sx={{
            backgroundColor: color,
            minHeight: "72px",
            margin: "-72px auto 0 auto",
            maxWidth: "650px",
            borderRadius: (theme) => `${theme.shape.borderRadius}px`,
          }}
        />
      </Box>
    </Grid>
  );

  const renderContent = () => (
    <Grid item xs={12} md={6}>
      {title || description ? (
        <StyledContent spacing={{ xs: 1, sm: 2 }}>
          <Typography variant="h3">{title}</Typography>
          <Typography>{description}</Typography>
        </StyledContent>
      ) : (
        children
      )}
    </Grid>
  );

  return (
    <Grid container spacing={4} alignItems="center" {...rest}>
      {isMobile || !reverse ? (
        <>
          {renderContent()}
          {renderImage()}
        </>
      ) : (
        <>
          {renderImage()}
          {renderContent()}
        </>
      )}
    </Grid>
  );
}

const StyledContent = styled(Stack)(
  ({ theme }) => `
  ul {
    list-style: none;
    ${theme.breakpoints.down("sm")} {
      padding-left: 0;
      margin: 0;
    }
  }
  li::before {
    color: ${theme.palette.secondary.main};
    content: "•";
    font-weight: bold;
    display: inline-block;
    width: 1em;
    ${theme.breakpoints.up("sm")} {
      margin-left: -1em;
    }
  }
`,
);

export default TwoColumnsSection;
